<template>
  <div style="margin:50px auto;display:block;">
      <v-layout row justify-center>
          <v-flex xs12 md8>
              <v-card flat style="border:solid 1px #ccc">
          <v-card-text>
              <p style="font-size:25px;font-weight:700;color:#00174A">
                  {{inmuebleSolo.pr_nombre}}
              </p>
              <p>
                  Código: {{inmuebleSolo.pr_codigo}}
              </p>
          </v-card-text>
          <v-card-text>
              <v-carousel hide-delimiters>
                <v-carousel-item
                :src="inmuebleSolo.img1"
                ></v-carousel-item>
                <v-carousel-item
                :src="inmuebleSolo.img2"
                ></v-carousel-item>
                <v-carousel-item
                :src="inmuebleSolo.img3"
                ></v-carousel-item>
                <v-carousel-item
                :src="inmuebleSolo.img4"
                ></v-carousel-item>
                <v-carousel-item
                :src="inmuebleSolo.img5"
                ></v-carousel-item>
                <v-carousel-item
                :src="inmuebleSolo.img6"
                ></v-carousel-item>
                <v-carousel-item
                :src="inmuebleSolo.img7"
                ></v-carousel-item>
                <v-carousel-item
                :src="inmuebleSolo.img8"
                ></v-carousel-item>
                <v-carousel-item
                :src="inmuebleSolo.img9"
                ></v-carousel-item>
                <v-carousel-item
                :src="inmuebleSolo.img10"
                ></v-carousel-item>
            </v-carousel>
          </v-card-text>

          <v-card-text class="pa-5 title" style="border:solid 1px #ccc">
              Datos del Inmueble
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text>
              <v-layout row justify-center>
                  <v-flex xs12 md6>
                      <div style="width:90%;margin:0 auto;display:block;">
                          <p>
                                <strong> Inmueble en: {{inmuebleSolo.pr_tipo}}</strong>
                            </p>
                            <p>
                                <strong>Tipo: {{inmuebleSolo.pr_categoria}}</strong>
                            </p>
                            <p>
                                <strong>Precio Ref: {{inmuebleSolo.pr_precio}}</strong>
                            </p>
                            <p>
                                <strong>Construcción: {{inmuebleSolo.pr_area_bruta}}</strong>
                            </p>
                            <p>
                                <strong>Terreno: {{inmuebleSolo.pr_area_terreno}}</strong>
                            </p>
                      </div>
                  </v-flex>
                   <v-flex xs12 md6>
                      <div style="width:90%;margin:0 auto;display:block;">
                        <p>
                            <strong> Habitaciones: {{inmuebleSolo.pr_habitaciones}}</strong>
                        </p>
                        <p>
                            <strong> Baños: {{inmuebleSolo.pr_banos}}</strong>
                        </p>
                        <p>
                            <strong> Estacionamiento: {{inmuebleSolo.pr_puestos}}</strong>
                        </p>
                        <p>
                            <strong> Años de Construcción: {{inmuebleSolo.pr_fecha_construccion}}</strong>
                        </p>
                      </div>
                  </v-flex>
              </v-layout>

          </v-card-text>
          
      </v-card>
          </v-flex>
          <v-flex xs12 md4>
              <v-card style="width:90%;margin:0 auto;display:block;" flat rounded>
                  <v-img :src="DatosDelAsesor.img_profile"></v-img>
                  <v-card-text class="title text-center">
                      {{DatosDelAsesor.username}}
                  </v-card-text>
                  <v-card-text>
                      <i class="fab fa-whatsapp pr-1"></i>Teléfono & Whatsapp <br>
                      <p>
                          {{DatosDelAsesor.tlf}}
                      </p>
                      <i class="fas fa-envelope-open-text"></i> Correo Electrónico <br>
                      <p>
                          {{DatosDelAsesor.email}}
                      </p>
                      <i class="fas fa-map-marker-alt"></i> Dirección <br>
                      <p>
                          {{DatosDelAsesor.direccion}}
                      </p>
                      <i class="fab fa-instagram"></i> Instagram <br>
                      <p>
                          {{DatosDelAsesor.instagram}}
                      </p>
                      <i class="fab fa-tiktok"></i> Tiktok <br>
                      <p>
                          {{DatosDelAsesor.tiktok}}
                      </p>
                      <p class="text-center pt-5 pb-5" style="border:solid 1px #ccc;border-radius:10px;">
                          Oficina <br>
                          {{DatosDelAsesor.oficina}}
                      </p>
                  </v-card-text>
              </v-card>
          </v-flex>
      </v-layout>
  </div>
</template>

<script>
import axios from 'axios'
export default {
    data(){
        return {
            id:this.$route.params.id,inmuebleSolo:[],DatosDelAsesor:[],
        }
    },
    methods:{
        loadAsesorResponsable(e){
            const URL_INMOGLOBAL_VE = 'https://inmoglobalve.com.ve/php/LoadAsesorResponsable.php';
            axios.post(URL_INMOGLOBAL_VE, {
                pr_asesor_uid: e
            }).then(res=>{
                this.DatosDelAsesor = res.data[0]
            })
        },
        async loadInmueblsse(){
        try {
        const URL_INMOGLOBAL_VE = 'https://inmoglobalve.com.ve/php/DetallesInmueble.php';
        await axios.post(URL_INMOGLOBAL_VE,{
            pr_uid: this.id
        }).then(res=>{
            this.inmuebleSolo = res.data[0]
            this.loadAsesorResponsable(res.data[0].pr_asesor_uid)
        })
        } catch (error) {
            console.log(error)
        }
    }
    },
    created(){
        this.loadInmueblsse()
    }
}
</script>

<style>

</style>